<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-group label="Quel modèle de véhicule ?">
      <b-form-select
        required
        v-model="vehicle.vehicleType"
        @change="onVehicleChange()"
      >
        <option
          v-for="vehicleModel in vehicleModels"
          :key="vehicleModel.rowKey"
          :value="vehicleModel"
          >{{ vehicleModel.brand + ' ' + vehicleModel.label }}</option
        >
      </b-form-select>
    </b-form-group>
    <b-form-group label="Quelle est la puissance de votre batterie ?">
      <b-form-select required v-model="vehicle.batteryPower">
        <option
          v-for="batteryPower in batteryPowers"
          :key="batteryPower.rowKey"
          :value="batteryPower"
          >{{ batteryPower.batteryPowerKWH + ' KwH' }}</option
        >
      </b-form-select>
    </b-form-group>
  </b-form>
</template>

<script>
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-driver-step2',
  mixins: [StepMixin],
  props: {
    vehicle: { type: Object, required: true },
    vehicleModels: { type: Array, default: () => [] }
  },
  data() {
    return {
      batteryPowers: []
    }
  },
  methods: {
    onVehicleChange() {
      this.batteryPowers = this.vehicle.vehicleType.versions
      this.vehicle.batteryPower = this.vehicle.vehicleType.versions[0]
    }
  }
}
</script>

<style></style>
