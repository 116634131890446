var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "form", attrs: { validated: _vm.validated, novalidate: "" } },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Quel modèle de véhicule ?" } },
        [
          _c(
            "b-form-select",
            {
              attrs: { required: "" },
              on: {
                change: function($event) {
                  return _vm.onVehicleChange()
                }
              },
              model: {
                value: _vm.vehicle.vehicleType,
                callback: function($$v) {
                  _vm.$set(_vm.vehicle, "vehicleType", $$v)
                },
                expression: "vehicle.vehicleType"
              }
            },
            _vm._l(_vm.vehicleModels, function(vehicleModel) {
              return _c(
                "option",
                { key: vehicleModel.rowKey, domProps: { value: vehicleModel } },
                [_vm._v(_vm._s(vehicleModel.brand + " " + vehicleModel.label))]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: "Quelle est la puissance de votre batterie ?" } },
        [
          _c(
            "b-form-select",
            {
              attrs: { required: "" },
              model: {
                value: _vm.vehicle.batteryPower,
                callback: function($$v) {
                  _vm.$set(_vm.vehicle, "batteryPower", $$v)
                },
                expression: "vehicle.batteryPower"
              }
            },
            _vm._l(_vm.batteryPowers, function(batteryPower) {
              return _c(
                "option",
                { key: batteryPower.rowKey, domProps: { value: batteryPower } },
                [_vm._v(_vm._s(batteryPower.batteryPowerKWH + " KwH"))]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }