<template>
  <b-form ref="form" :validate="validated" novalidate>
    <b-form-group label="Quel est le numéro de votre plaque ?">
      <b-form-input
        :value="vehicle.plateNumber"
        @input="onInput"
        pattern="[A-Z0-9 -]+"
        required
      />
    </b-form-group>
    <b-form-row>
      <b-col class="button my-5">
        <ImagePicker v-model="vehicle.registrationCert" caption="Carte grise" />
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import ImagePicker from '@/components/Registration/ImagePicker.vue'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-driver-step3',
  components: { ImagePicker },
  mixins: [StepMixin],
  props: {
    vehicle: { type: Object, required: true }
  },
  methods: {
    onInput(value) {
      this.vehicle.plateNumber = value.toUpperCase()
    }
  }
}
</script>

<style lang="scss">
#register-driver,
#register-provider {
  .form-row {
    .col.button {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
