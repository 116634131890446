var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "form", attrs: { validate: _vm.validated, novalidate: "" } },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Quel est le numéro de votre plaque ?" } },
        [
          _c("b-form-input", {
            attrs: {
              value: _vm.vehicle.plateNumber,
              pattern: "[A-Z0-9 -]+",
              required: ""
            },
            on: { input: _vm.onInput }
          })
        ],
        1
      ),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { staticClass: "button my-5" },
            [
              _c("ImagePicker", {
                attrs: { caption: "Carte grise" },
                model: {
                  value: _vm.vehicle.registrationCert,
                  callback: function($$v) {
                    _vm.$set(_vm.vehicle, "registrationCert", $$v)
                  },
                  expression: "vehicle.registrationCert"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }