var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "223.541",
        height: "111.771",
        viewBox: "0 0 223.541 111.771"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "a",
                y1: "0.5",
                x2: "1",
                y2: "0.5",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", {
                attrs: { offset: "0.163", "stop-color": "#00a3f3" }
              }),
              _c("stop", {
                attrs: { offset: "0.613", "stop-color": "#00cece" }
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#00e3c0" } })
            ],
            1
          )
        ],
        1
      ),
      _c("g", { attrs: { transform: "translate(-72 -202.366)" } }, [
        _c("path", {
          staticClass: "a",
          attrs: {
            d: "M0,116.234a111.771,111.771,0,0,1,223.541.017",
            transform: "translate(72 197.885)"
          }
        }),
        _c(
          "g",
          {
            attrs: {
              transform: "matrix(0.921, -0.391, 0.391, 0.921, 147.403, 257.788)"
            }
          },
          [
            _c("path", {
              staticClass: "b",
              attrs: {
                d:
                  "M61.25,41.3H5.84A4.844,4.844,0,0,1,1,36.46V5.84A4.844,4.844,0,0,1,5.84,1H61.25a4.844,4.844,0,0,1,4.84,4.84V36.46A4.844,4.844,0,0,1,61.25,41.3Z"
              }
            }),
            _c("circle", {
              staticClass: "c",
              attrs: {
                cx: "9.43",
                cy: "9.43",
                r: "9.43",
                transform: "translate(6.31 11.21)"
              }
            }),
            _c("path", {
              staticClass: "d",
              attrs: {
                d:
                  "M19.2,26.01a3.557,3.557,0,0,0-.98-2.46,1.422,1.422,0,0,1-.02-1.98,3.58,3.58,0,0,0,.99-2.76,3.624,3.624,0,0,0-3.53-3.31,3.587,3.587,0,0,0-2.67,6.04,1.449,1.449,0,0,1,0,2.01A3.566,3.566,0,0,0,12,26.01"
              }
            }),
            _c("line", {
              staticClass: "e",
              attrs: { x2: "30.67", transform: "translate(28.85 14.3)" }
            }),
            _c("line", {
              staticClass: "e",
              attrs: { x2: "18.1", transform: "translate(28.85 21.15)" }
            }),
            _c("line", {
              staticClass: "e",
              attrs: { x2: "29.46", transform: "translate(28.85 28)" }
            })
          ]
        ),
        _c(
          "g",
          { attrs: { transform: "translate(146.102 251.088) rotate(-13)" } },
          [
            _c("path", {
              staticClass: "e",
              attrs: {
                d:
                  "M61.25,41.3H5.84A4.844,4.844,0,0,1,1,36.46V5.84A4.844,4.844,0,0,1,5.84,1H61.25a4.844,4.844,0,0,1,4.84,4.84V36.46A4.844,4.844,0,0,1,61.25,41.3Z"
              }
            }),
            _c("circle", {
              staticClass: "f",
              attrs: {
                cx: "9.43",
                cy: "9.43",
                r: "9.43",
                transform: "translate(6.31 11.21)"
              }
            }),
            _c("path", {
              staticClass: "d",
              attrs: {
                d:
                  "M19.2,26.01a3.557,3.557,0,0,0-.98-2.46,1.422,1.422,0,0,1-.02-1.98,3.58,3.58,0,0,0,.99-2.76,3.624,3.624,0,0,0-3.53-3.31,3.587,3.587,0,0,0-2.67,6.04,1.449,1.449,0,0,1,0,2.01A3.566,3.566,0,0,0,12,26.01"
              }
            }),
            _c("line", {
              staticClass: "e",
              attrs: { x2: "30.67", transform: "translate(28.85 14.3)" }
            }),
            _c("line", {
              staticClass: "e",
              attrs: { x2: "18.1", transform: "translate(28.85 21.15)" }
            }),
            _c("line", {
              staticClass: "e",
              attrs: { x2: "29.46", transform: "translate(28.85 28)" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }