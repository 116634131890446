<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-form-row>
      <b-col class="button">
        <ImagePicker
          v-model="idCard"
          caption="Pièce d'identité"
          icon="id-card"
        />
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import ImagePicker from '@/components/Registration/ImagePicker.vue'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-driver-step5',
  components: { ImagePicker },
  mixins: [StepMixin],
  props: {
    idCard: { type: Object, required: true }
  }
}
</script>

<style lang="scss">
#register-driver,
#register-provider {
  .form-row {
    .col.button {
      display: flex;
      justify-content: center;
      margin: 47px 0;
    }
  }
}
</style>
