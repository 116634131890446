var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "230.024",
        height: "120.408",
        viewBox: "0 0 230.024 120.408"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "a",
                y1: "0.5",
                x2: "1",
                y2: "0.5",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", {
                attrs: { offset: "0.163", "stop-color": "#00a1f6" }
              }),
              _c("stop", {
                attrs: { offset: "0.613", "stop-color": "#00cece" }
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#00e3c0" } })
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "b",
                x1: "0.001",
                y1: "0.498",
                x2: "1",
                y2: "0.498",
                gradientUnits: "objectBoundingBox"
              }
            },
            [
              _c("stop", {
                attrs: { offset: "0.265", "stop-color": "#00a1f6" }
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "#00cece" } })
            ],
            1
          )
        ],
        1
      ),
      _c("g", { attrs: { transform: "translate(0 0)" } }, [
        _c("path", {
          staticClass: "a",
          attrs: {
            d: "M0,116.234a111.771,111.771,0,0,1,223.541.017",
            transform: "translate(0 2.91)"
          }
        }),
        _c("g", { attrs: { transform: "translate(3.579 56.743)" } }, [
          _c("line", {
            staticClass: "b",
            attrs: { y2: "23.143", transform: "translate(16.396 27.448)" }
          }),
          _c("path", {
            staticClass: "c",
            attrs: {
              d:
                "M4.908,46.623l-.082-2.309A9.919,9.919,0,0,1,22.36,37.963a8.093,8.093,0,0,1,1.682-.2,7.624,7.624,0,0,1,7.571,6.746,9.826,9.826,0,0,1-6.532,17.171,9.9,9.9,0,0,1-2.738-.4,16.919,16.919,0,0,1-2.87.247,19.849,19.849,0,0,1-7.01-1.122,8.252,8.252,0,0,1-2.227.313,8.053,8.053,0,0,1-5.328-14.1Z",
              transform: "translate(-2.17 -34.4)"
            }
          }),
          _c("line", {
            staticClass: "b",
            attrs: {
              y1: "3.744",
              x2: "5.427",
              transform: "translate(16.396 35.959)"
            }
          })
        ]),
        _c("g", { attrs: { transform: "translate(78.879 93.497)" } }, [
          _c("ellipse", {
            staticClass: "d",
            attrs: {
              cx: "3.563",
              cy: "3.563",
              rx: "3.563",
              ry: "3.563",
              transform: "translate(11.068 18.784)"
            }
          }),
          _c("ellipse", {
            staticClass: "d",
            attrs: {
              cx: "3.563",
              cy: "3.563",
              rx: "3.563",
              ry: "3.563",
              transform: "translate(39.704 18.784)"
            }
          }),
          _c("path", {
            staticClass: "b",
            attrs: {
              d:
                "M99.664,79.029h1.6a3.986,3.986,0,0,0,2.969-1.32l.231-.264a3.987,3.987,0,0,0,1.006-2.639V70.7A6.991,6.991,0,0,0,99.7,63.8l-3.414-.61c-.2-.313-.412-.627-.643-.924a14.872,14.872,0,0,0-11.679-5.559c-4.47-.033-10.573-.066-12.965.066-3.959.214-9.534,6.713-9.534,6.713a23.337,23.337,0,0,0-9.633,1.946,6.926,6.926,0,0,0-4.008,6.334v3.283A3.981,3.981,0,0,0,49.5,78.3h0a3.943,3.943,0,0,0,2.293.726h2.21",
              transform: "translate(-47.82 -56.682)"
            }
          }),
          _c("line", {
            staticClass: "b",
            attrs: { x2: "11.349", transform: "translate(23.275 22.347)" }
          })
        ]),
        _c("line", {
          staticClass: "b",
          attrs: { y2: "28.784", transform: "translate(168.662 87.622)" }
        }),
        _c("path", {
          staticClass: "c",
          attrs: {
            d:
              "M48.344,76.828a11.307,11.307,0,0,0-19.712-8,9.082,9.082,0,0,0-1.913-.3,8.725,8.725,0,0,0-9.039,8.363",
            transform: "translate(11.483 42.266)"
          }
        }),
        _c("path", {
          staticClass: "c",
          attrs: {
            d:
              "M127.377,47.593l.115-2.92a12.5,12.5,0,0,0-22.1-8,10.013,10.013,0,0,0-2.128-.247,9.592,9.592,0,0,0-9.534,8.511,12.374,12.374,0,0,0,8.231,21.625,12.646,12.646,0,0,0,3.464-.495,20.322,20.322,0,0,0,3.612.313,24.929,24.929,0,0,0,8.825-1.419,9.848,9.848,0,0,0,2.8.4,10.145,10.145,0,0,0,6.713-17.765Z",
            transform: "translate(58.176 20.895)"
          }
        }),
        _c("g", { attrs: { transform: "translate(49.073 32.743)" } }, [
          _c("g", { attrs: { transform: "translate(13.312)" } }, [
            _c("path", {
              staticClass: "e",
              attrs: {
                d:
                  "M131.36,23.82h-46.6a1.359,1.359,0,0,0-1.353,1.353H72.143a1.353,1.353,0,1,0,0,2.705h46.615a1.359,1.359,0,0,0,1.353-1.353h11.266a1.359,1.359,0,0,0,1.353-1.353A1.384,1.384,0,0,0,131.36,23.82Z",
                transform: "translate(-16.406 -17.271)"
              }
            }),
            _c("path", {
              staticClass: "c",
              attrs: {
                d:
                  "M121.1,22.555H106.283A1.359,1.359,0,0,1,104.93,21.2h0a1.359,1.359,0,0,1,1.353-1.353H121.1a1.359,1.359,0,0,1,1.353,1.353h0A1.359,1.359,0,0,1,121.1,22.555Z",
                transform: "translate(5.768 -19.85)"
              }
            }),
            _c("path", {
              staticClass: "c",
              attrs: {
                d:
                  "M120.366,22.555h-1.913A1.359,1.359,0,0,1,117.1,21.2h0a1.359,1.359,0,0,1,1.353-1.353h1.913a1.359,1.359,0,0,1,1.353,1.353h0A1.359,1.359,0,0,1,120.366,22.555Z",
                transform: "translate(13.673 -19.85)"
              }
            }),
            _c("path", {
              staticClass: "f",
              attrs: {
                d:
                  "M69.144,36.8H48.311a1.376,1.376,0,0,0-1.369,1.369H39.189a1.369,1.369,0,1,0,0,2.738H60.022a1.376,1.376,0,0,0,1.369-1.369h7.753a1.369,1.369,0,1,0,0-2.738Z",
                transform: "translate(-37.82 -8.841)"
              }
            })
          ]),
          _c("path", {
            staticClass: "g",
            attrs: {
              d:
                "M45.915,34.875H31.1a1.359,1.359,0,0,1-1.353-1.353h0A1.359,1.359,0,0,1,31.1,32.17H45.915a1.359,1.359,0,0,1,1.353,1.353h0A1.359,1.359,0,0,1,45.915,34.875Z",
              transform: "translate(-29.75 -11.848)"
            }
          })
        ]),
        _c("path", {
          staticClass: "c",
          attrs: {
            d:
              "M124.992,75.971a8.806,8.806,0,0,1,15.34-6.235,6.336,6.336,0,0,1,1.485-.231,6.76,6.76,0,0,1,7.027,6.516",
            transform: "translate(81.179 43.404)"
          }
        }),
        _c("ellipse", {
          staticClass: "g",
          attrs: {
            cx: "14.829",
            cy: "14.829",
            rx: "14.829",
            ry: "14.829",
            transform: "translate(59.844 0)"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }