<template>
  <b-form ref="form" class="card-form mb-3" :validated="validated" novalidate>
    <IdCardIcon class="card-icon" />
    <b-form-row>
      <b-col>
        <b-form-group label="Numéro de carte">
          <card-number
            ref="cardNumber"
            id="card-number"
            :stripe="stripeKey"
            :options="options"
            @change="cardDetails.number = $event.complete"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Date d'expiration">
          <card-expiry
            ref="cardExpiry"
            id="card-expiry"
            :stripe="stripeKey"
            :options="options"
            @change="cardDetails.expiry = $event.complete"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="CVC">
          <card-cvc
            ref="cardCvc"
            id="card-cvc"
            :stripe="stripeKey"
            :options="options"
            @change="cardDetails.cvc = $event.complete"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Nom du titulaire de la carte">
          <b-form-input
            v-model="cardDetails.owner"
            placeholder="JOHN DOE"
            required
          />
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex'
import { CardNumber, CardExpiry, CardCvc } from 'vue-stripe-elements-plus'
import IdCardIcon from '@/components/svg/IdCardIcon.vue'
import StepMixin from '@/mixins/step.js'

export default {
  name: 'register-driver-step4',
  components: {
    IdCardIcon,
    CardNumber,
    CardExpiry,
    CardCvc
  },
  mixins: [StepMixin],
  props: {
    //cardDetails: { type: Object, required: true }
  },
  data() {
    return {
      cardDetails: {
        number: false,
        expiry: false,
        cvc: false,
        owner: undefined
      },
      stripeKey: process.env.VUE_APP_STRIPE_API_PublicKey,
      options: {
        style: {
          base: {
            fontFamily: "'Source Sans Pro', sans-serif",
            fontWeight: 400,
            fontSize: '14px'
          }
        }
      }
    }
  },
  computed: {
    complete() {
      return (
        this.cardDetails.number &&
        this.cardDetails.expiry &&
        this.cardDetails.cvc
      )
    }
  },
  methods: {
    ...mapActions(['createCreditCard']),
    isCreditCardCompleted() {
      return this.complete
    },
    registerCreditCardAsync() {
      return this.createCreditCard({
        api: this.$apiService,
        pm: {
          card: this.$refs.cardNumber.$refs.element._element,
          billing_details: { name: this.cardDetails.owner }
        }
      }).catch(err => {
        console.error('Something went wrong: ', err)
        throw new Error('Something went wrong: ' + err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#register-driver {
  .card-form {
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;

    svg.card-icon {
      fill: $dark;
      stroke: $dark;
      margin-bottom: 20px;
    }

    .form-control {
      padding-left: 0;
    }
  }
}
</style>

<style lang="scss">
@import '@/styles.scss';

#register-driver {
  .card-form {
    .col-form-label {
      margin-bottom: 15px;
      font-weight: $font-weight-bold;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.65px;
    }

    .StripeElement {
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 14px;
      padding: 0.375rem 0.75rem;
      padding-left: 0;
    }
  }
}
</style>
