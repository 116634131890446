<template>
  <div class="decorated fill">
    <HeaderNavigation title="ajouter un véhicule" />
    <b-container id="register-driver" class="main-container">
      <b-row>
        <b-col>
          <h1>{{ currentCaption }}</h1>
          <Stepper :steps="steps.length" :step="step" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <VehicleBlue v-if="!lastStep" />
          <IdFilesBlue v-else />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Step2
            ref="step2"
            v-if="step == 2"
            :vehicle="vehicle"
            :vehicleModels="refs.vehicleModels"
          />
          <Step3 ref="step3" v-else-if="step == 3" :vehicle="vehicle" />
          <Step4 ref="step4" v-else-if="step == 4" />
          <Step5 ref="step5" v-else-if="step == 5" :idCard="idCard" />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-button
            v-if="!lastStep"
            v-loader.disable="busy"
            variant="light"
            class="next"
            @click="nextStep"
          >
            SUIVANT
          </b-button>
          <b-button
            v-else
            v-loader.disable="registering"
            variant="light"
            class="next"
            router-tag="button"
            :to="routes.REGISTRATION_FINISHED"
            :disabled="!isIdCardSet"
          >
            TERMINER
          </b-button>
        </b-col>
      </b-row>
      <AlertMessage
        ref="alertFileUpload"
        message="Seuls les fichiers images (PNG / JPEG) ainsi que PDF sont supportés"
      ></AlertMessage>
    </b-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import Stepper from '@/components/Registration/Stepper.vue'
import Step2 from '@/components/Registration/RegisterDriver/Step2.vue'
import Step3 from '@/components/Registration/RegisterDriver/Step3.vue'
import Step4 from '@/components/Registration/RegisterDriver/Step4.vue'
import Step5 from '@/components/Registration/RegisterDriver/Step5.vue'
import VehicleBlue from '@/components/svg/VehicleBlue.vue'
import IdFilesBlue from '@/components/svg/IdFilesBlue.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import ProfileRegistrationMixin from '@/mixins/profile-registration.js'
import { Routes } from '@/router.js'

const STEPS = Object.freeze([
  {},
  {
    caption:
      'Ajoutez votre véhicule à votre compte pour rejoindre notre communauté'
  },
  {
    caption: 'Ajoutez une photographie de votre carte grise'
  },
  {
    caption: 'Ajoutez une carte de paiement'
  },
  {
    caption: "Ajoutez une photographie de votre pièce d'identité"
  }
])

export const RegisterDriverStepCount = STEPS.length

export default {
  name: 'register-driver',
  components: {
    Stepper,
    Step2,
    Step3,
    Step4,
    Step5,
    VehicleBlue,
    IdFilesBlue,
    AlertMessage,
    HeaderNavigation
  },
  mixins: [ProfileRegistrationMixin],
  data() {
    return {
      step: 2,
      steps: STEPS,
      vehicle: {
        vehicleType: null,
        batteryPower: null,
        plateNumber: null,
        registrationCert: {
          file: undefined,
          type: undefined,
          url: undefined,
          isValid: undefined
        }
      },
      idCard: {
        file: undefined,
        type: undefined,
        url: undefined,
        isValid: undefined
      },
      busy: false,
      registering: false,
      routes: Routes
    }
  },
  computed: {
    refs() {
      return { vehicleModels: this.$store.getters.vehicleTypes }
    },
    isRegCertSet() {
      return this.isDocumentSet(this.vehicle.registrationCert)
    },
    isIdCardSet() {
      return this.isDocumentSet(this.idCard)
    }
  },
  mounted() {
    this.loadVehicleTypes({ api: this.$apiService, refresh: true })
    this.loadIdCard({ api: this.$apiService }).then(
      idCard => (this.idCard = idCard)
    )
  },
  async beforeRouteLeave(to, from, next) {
    if (to.name == Routes.MENU.name) next()
    else {
      if (!this.isCurrentFormValid()) {
        next(false)
      } else {
        try {
          this.registering = true
          const proms = []

          proms.push(
            this.$apiService
              .postUserVehicleAsync(this.vehicle)
              .then(vehicle => {
                this.vehicle.id = vehicle.id

                return this.$apiService
                  .putVehicleRegistrationCertificateAsync(
                    vehicle.id,
                    this.vehicle.registrationCert.file
                  )
                  .catch(err =>
                    console.error(
                      'Error when uploading registration certificate: ',
                      err
                    )
                  )
                  .then(() => vehicle)
              })
              .catch(err => console.error('Error when posting vehicle: ', err))
          )

          if (this.idCard.file) {
            proms.push(
              this.$apiService
                .putUserIdCardAsync(this.idCard.file)
                .catch(err =>
                  console.error("Error when uploading driver's ID card: ", err)
                )
            )
          }

          await Promise.all(proms).then(([vehicle]) => {
            this.registerDriver({
              vehicle,
              documents: {
                idCard: this.idCard,
                registrationCert: this.vehicle.registrationCert
              }
            })
          })

          next()
        } catch (err) {
          console.error(
            "Uncaught error while updating drivers's profile: ",
            err
          )

          next(false)
        } finally {
          this.registering = false
        }
      }
    }
  },
  methods: {
    ...mapActions(['loadVehicleTypes', 'loadIdCard', 'registerDriver']),
    isDocumentSet(doc) {
      return !!doc.url
    },
    checkStepRules() {
      return !(
        (this.step == 3 && !this.isRegCertSet) ||
        (this.step == 4 && !this.$refs.step4.isCreditCardCompleted()) ||
        (this.step == 5 && !this.isIdCardSet)
      )
    },
    async nextStep() {
      if (!this.lastStep && this.isCurrentFormValid()) {
        if (this.step == 4) {
          this.busy = true

          await this.$refs.step4
            .registerCreditCardAsync()
            .catch(err => console.error("Couldn't register Credit Card: ", err))
            .finally(() => (this.busy = false))
        }

        this.step++

        if (this.step == 4 && this.$store.getters.isCreditCardSet) {
          this.step++
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles.scss';

#register-driver {
  text-align: center;

  h1 {
    margin: 94px auto 9px;
    height: 34px;
    font-size: 13px;
    font-weight: $font-weight-bold;
    line-height: 17px;
    text-transform: uppercase;
  }

  svg {
    margin: 6px auto 19px;
    width: 230px;
    height: 120px;
  }
}
</style>
